<template>
  <div class="step2">
    <div class="box-card" :body-style="{ padding: 0 }">
      <div class="yellos mar20">
        说明：若只更新过期资质，请直接点击“更新资质”，若需更新
      </div>
      <div class="step">
        <div class="qualifications">
          <div
            v-for="item in businessInfo.qualification"
            :key="item.id"
            class="min_qual"
          >
            <div class="picture">
              <div
                class="seepho exceed"
                style="margin-bottom: 10px; font-weight: bold; font-size: 16px"
                :title="item.tmpName"
              >
                {{ item.tmpName }}
              </div>
              <el-image
                class="photo"
                :src="v"
                :preview-src-list="item.picture"
                v-for="(v, i) in item.picture"
                :key="i"
              >
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
              </el-image>
            </div>
            <div class="leftQual">
              <div v-for="(v, i) in item.text" :key="i" class="text">
                {{ v.key }}:{{ v.val }}
              </div>
              <div class="text">
                有效期：
                <span v-if="item.effectiveEndTime == '9999-12-31'">长期</span>
                <span v-else>
                  {{ item.effectiveEndTime }}
                  <span class="redin">{{
                    item.qualificationStatus == 1
                      ? "即将过期"
                      : item.qualificationStatus == 2
                      ? "已过期"
                      : ""
                  }}</span>
                </span>
              </div>
            </div>
            <div class="fx leftQual" style="width: 250px; margin-left: 50px">
              <div class="zhutxt">图片：</div>
              <div>
                <el-image
                  class="photo"
                  :src="v"
                  :preview-src-list="item.legend"
                  v-for="(v, i) in item.legend"
                  :key="i"
                >
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                </el-image>
              </div>
            </div>
          </div>
        </div>
        <div class="business-2">
          <div>经营范围</div>
          <div v-for="(item, index) in businessScopeList" :key="index">
            <div class="business-1">
              <div class="title exceed" :title="item.type">
                {{ item.type }}
              </div>
              <div class="swith">
                <el-switch
                  :disabled="$route.query.type == 1 ? true : false"
                  v-model="item.business_id"
                  active-color="#06B7AE"
                  inactive-color="#e6e8eb"
                  :active-value="business_id"
                  :inactive-value="null"
                >
                </el-switch>
              </div>
              <div class="input-box">
                <el-date-picker
                  editable
                  format="yyyy-MM-dd"
                  v-model="item.expiration_time"
                  type="date"
                  placeholder="选择有效日期"
                  :class="{ hint: item.hint }"
                  :picker-options="pickerOptions"
                  @change="
                    item.expiration_time == '9999-12-31 00:00:00'
                      ? (item.isLongTime = true)
                      : (item.isLongTime = false)
                  "
                  value-format="yyyy-MM-dd"
                  :disabled="
                    item.business_id == null ||
                    $route.query.type == 1 ||
                    item.expiration_time == '9999-12-31 00:00:00'
                      ? true
                      : false
                  "
                >
                </el-date-picker>
                <div class="hintbox">
                  <span v-if="item.hint" class="hinttxet"
                    >{{ item.type }}有效期不能为空</span
                  >
                </div>
              </div>
              <div class="checks">
                <el-checkbox
                  v-model="item.isLongTime"
                  :disabled="item.business_id == null"
                  @change="
                    item.expiration_time == '9999-12-31 00:00:00'
                      ? (item.expiration_time = '')
                      : (item.expiration_time = '9999-12-31 00:00:00')
                  "
                  >长期</el-checkbox
                >
              </div>
            </div>
            <div class="redin">
              {{
                item.qualificationStatus == 1
                  ? "即将过期"
                  : item.qualificationStatus == 2
                  ? "已过期"
                  : ""
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="operate-btn" v-if="!$route.query.type == 1">
      <div
        class="go-back"
        @click="$router.push({ path: '/merchantCenterList' })"
      >
        返回
      </div>
      <div class="save" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("merchantCenterList"); //vuex公共库
const Audit = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  name: "UpQualification",
  data() {
    return {
      cities: [],
      subForm: {
        // 企业名称
        name: "",
        // 企业类型
        enterpriseType: [],
        // 证件类型
        isUnity: "1",
        qtype: "公章",
        signName: "",
      },
      businessId: "",
      business_id: "",
      businessScopeList: [], //经营类别
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      fileList: [],
      subRule: {
        name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "长度在 3 到 20 个字符",
            trigger: "blur",
          },
        ],
        enterpriseType: [
          { required: true, message: "请选择企业类型", trigger: "blur" },
        ],
        isUnity: [
          { required: true, message: "请选择是否三证合一", trigger: "blur" },
        ],
      },
      allQul: [],
      businessInfo: {},
    };
  },
  computed: {},
  async created() {
    this.businessId = this.$route.query.id;
    this.business_id = this.$route.query.id;
    await this.getBusiness();
    await this.getBUsinds(); //获取经营类别
  },
  methods: {
    // 获取商户资质
    ...mapActions(["getCredentials", "getBusinessScopeList"]),
    ...Audit.mapActions(["postscopeConfig"]),
    async getBusiness() {
      var data = await this.getCredentials({
        businessId: this.$route.query.id,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      });
      data.qualification.forEach((v) => {
        this.$set(v, "picture", []);
        this.$set(v, "text", []);
        v.items.forEach((v1) => {
          if (v1.itemType == 3) {
            v.picture.push(v1.imgUrl);
          }
          if (v1.itemType == 1) {
            v.text.push({ key: v1.itemName, val: v1.itemText });
          }
        });
      });
      this.businessInfo.qualification = data.qualification;
      // data.scopeInfo.forEach((v) => {
      //   if (v.expirationTime == "9999-12-31 00:00:00") {
      //     v.isLongTime = true;
      //   } else {
      //     v.isLongTime = false;
      //   }
      //   if (v.action == "add") {
      //     v.businessId = this.$route.query.id;
      //   }
      // });
      this.businessInfo.scopeInfo = data.scopeInfo;
    },
    //获取经营类别
    async getBUsinds() {
      let data = await this.getBusinessScopeList({
        business_id: this.$route.query.id,
      });
      data.forEach((v, i) => {
        if (v.expiration_time == "9999-12-31 00:00:00") {
          v.isLongTime = true;
        } else {
          v.isLongTime = false;
        }
        if (v.action == "add") {
          v.business_id = this.$route.query.id;
        }
      });
      this.businessScopeList = data;
    },
    // 保存
    async save() {
      let num = this.businessScopeList.filter((v) => {
        return v.business_id != null;
      });
      if (num.length == 0) {
        this.$message.error("请选择经营范围");
        return false;
      }
      let notime = num.filter((v) => {
        return v.expiration_time == null;
      });
      if (notime.length > 0) {
        this.$message.error(`请选择 ${notime[0].type}有效期`);
        return false;
      }
      let params = JSON.stringify(num);
      params = JSON.parse(params);
      params.forEach((v) => {
        v.business_scope_id = v.id;
        delete v.action;
        delete v.id;
        delete v.isLongTime;
        delete v.type;
        delete v.qualificationstatus;
      });
      let data = await this.postscopeConfig(params);
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      }
    },
    getQualification() {},
    // 获取资质模板
    getTemplate1(qualification) {},

    // 提交资质
    qualificationUpload() {},
  },
};
</script>
<style lang='scss' scoped>
.el-upload--picture-card {
  height: 60px;
  width: 60px;
  line-height: 60px;
}
.el-upload-list__item {
  height: 60px;
  width: 60px;
}
.elimg-box .el-image {
  margin-top: 10px;
  width: 100px !important;
  height: 100px !important;
}
</style>
<style lang="scss" scoped>
.hesd {
  height: 100px;
}

.step2 {
  .box-card {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 80px;
    .step2Title {
      display: flex;
      padding: 20px 0;
      border-bottom: 1px solid #eee;
      .leftTitle {
        padding-left: 40px;
        border-left: 2px solid blue;
        font-size: 20px;
      }
    }
    .step {
      padding-top: 20px;
      display: flex;
      .stepForm {
        padding: 30px 10px;
      }
      .qualifications {
        // display: grid;
        // grid-template-columns: repeat(2, 50%);
        border: 1px solid #eee;
        // margin-top: -20px;
        padding: 20px;
        .min_qual {
          padding-bottom: 10px;
          width: 100%;
          display: flex;
          // border: 1px solid #eee;
          margin-bottom: 20px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #eee;
          .leftQual {
            width: 200px;
            height: 150px;

            .text {
              font-size: 12px;
              line-height: 24px;
              color: #666;
            }
            .seepho {
              // margin-top: 20px;
              margin-bottom: 24px;
              font-size: 16px;
              color: #333;
            }
            .photo {
              width: 120px;
              height: 120px;
            }
          }
          .picture {
            .photo {
              width: 150px;
              height: 150px;
              border-radius: 10px;
              margin-right: 50px;
            }
          }
          .rightQual {
            flex: 1;
            .allName {
              padding: 13px 20px;
              .time-box {
                width: 100%;
                min-width: 300px;
              }
              .span-wwidth {
                margin-right: 3px;
                width: 100px !important;
              }
            }
            .allQ {
              background-color: #eee;
              width: 100%;
              // height: 140px;
              // display: flex;
              .minAllQ {
                display: flex;
                text-align: center;
              }
            }
          }
        }
      }
    }
    .footer {
      text-align: right;
    }
  }
}
.operate-btn {
  bottom: 30px;
  height: 80px;
  background-color: #fff;
  margin-top: 0;
  position: absolute;
  z-index: 99;
  .save {
    text-align: center;
    line-height: 32px;
    margin-left: 10px;
    width: 88px;
    height: 32px;
    background: #00bfbf;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }
  .btn {
    border: 1px solid #999;
    background: #fff;
    color: #999;
  }
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 200px;
    height: 100%;
    border-radius: 4px;
    background: #aaaaaa;
  }
}
.business-2 {
  flex: 1;
  border: 1px solid #eee;
  padding: 20px;

  .business-1 {
    margin-top: 10px;
    min-width: 500px;
    height: 60px;
    line-height: 40px;
    display: flex;

    .title {
      min-width: 70px;
      font-size: 14px;
      width: 20%;
      color: black !important;
      height: 100%;
    }
    .input-box {
      margin-left: 10px;
      min-width: 150px;
      height: 60px;
    }
  }
  .checks {
    margin-left: 10px;
  }
}
</style>
