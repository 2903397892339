var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"returnAddress"},[_c('el-descriptions',{attrs:{"column":1}},[_c('el-descriptions-item',{attrs:{"label":"发货地址"}},[(
          _vm.returnArea.delivery_province_name ||
          _vm.returnArea.delivery_city_name ||
          _vm.returnArea.delivery_district_name
        )?[_vm._v(" "+_vm._s(_vm.returnArea.delivery_province_name)+"- "+_vm._s(_vm.returnArea.delivery_city_name)+" -"+_vm._s(_vm.returnArea.delivery_district_name)+" ")]:[_vm._v(" /")]],2),_c('el-descriptions-item',{attrs:{"label":"退货地址"}},[(
          _vm.returnArea.return_province_name ||
          _vm.returnArea.return_city_name ||
          _vm.returnArea.return_district_name
        )?[_vm._v(" "+_vm._s(_vm.returnArea.return_province_name)+"- "+_vm._s(_vm.returnArea.return_city_name)+" -"+_vm._s(_vm.returnArea.return_district_name)+" ")]:[_vm._v(" /")]],2),_c('el-descriptions-item',{attrs:{"label":"详细地址"}},[_vm._v(" "+_vm._s(_vm.returnArea.address || "/")+" ")]),_c('el-descriptions-item',{attrs:{"label":"收货人姓名"}},[_vm._v(" "+_vm._s(_vm.returnArea.name || "/")+" ")]),_c('el-descriptions-item',{attrs:{"label":"手机号"}},[_vm._v(" "+_vm._s(_vm.returnArea.telephone || "/")+" ")]),_c('el-descriptions-item',{attrs:{"label":"退货说明"}},[_c('div',{staticClass:"desc_"},[_vm._v(_vm._s(_vm.returnArea.desc_ || "/"))])])],1),(!_vm.$route.query.type == 1)?_c('div',{staticClass:"operate-btn absbtn"},[_c('div',{staticClass:"save1",on:{"click":function($event){return _vm.$router.push({ path: '/merchantCenterList' })}}},[_vm._v(" 返回 ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }