<template>
  <div class="business">
    <div class="business-1-box scorr-roll">
      <div class="universal-title">资质图片</div>
      <div class="picture-box" v-if="memberAudituserqualify.length > 0">
        <div
          class="picture-item"
          v-for="(item, index) in memberAudituserqualify"
          :key="index"
        >
          <div v-if="item.picture.length > 0">
            <div>{{ item.item_name }}</div>
            <div class="img">
              <div class="upImg" v-for="(v, i) in item.picture" :key="i">
                <el-image :src="v" :preview-src-list="item.picture"></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="box-message-1" v-else>暂未上传相关资质</div>
      <div class="universal-title">经营类别</div>
      <div
        class="business-1"
        v-for="(item, index) in businessScopeList"
        :key="index"
      >
        <div class="title exceed" :title="item.type">{{ item.type }}</div>
        <div class="swith">
          <el-switch
            :disabled="$route.query.type == 1 ? true : false"
            v-model="item.business_id"
            active-color="#06B7AE"
            inactive-color="#e6e8eb"
            :active-value="business_id"
            :inactive-value="null"
          >
          </el-switch>
        </div>
        <div class="input-box">
          <el-date-picker
            editable
            format="yyyy-MM-dd"
            v-model="item.expiration_time"
            type="datetime"
            placeholder="选择有效日期"
            :class="{ hint: item.hint }"
            :picker-options="pickerOptions"
            @change="
              item.expiration_time == '9999-12-31 00:00:00'
                ? (item.isLongTime = true)
                : (item.isLongTime = false)
            "
            value-format="yyyy-MM-dd HH:mm:ss"
            :disabled="
              item.business_id == null ||
              $route.query.type == 1 ||
              item.expiration_time == '9999-12-31 00:00:00'
                ? true
                : false
            "
          >
          </el-date-picker>
          <div class="hintbox">
            <span v-if="item.hint" class="hinttxet"
              >{{ item.name }}有效期不能为空</span
            >
          </div>
        </div>
        <div class="checks">
          <el-checkbox
            v-model="item.isLongTime"
            :disabled="item.business_id == null"
            @change="
              item.expiration_time == '9999-12-31 00:00:00'
                ? (item.expiration_time = '')
                : (item.expiration_time = '9999-12-31 00:00:00')
            "
            >长期</el-checkbox
          >
        </div>
      </div>
    </div>
    <div class="operate-btn" v-if="!$route.query.type == 1">
      <div
        class="go-back"
        @click="$router.push({ path: '/merchantCenterList' })"
      >
        返回
      </div>
      <div class="save" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const { mapState, mapActions, mapMutations } = createNamespacedHelpers("Audit"); //vuex公共库
export default {
  data() {
    return {
      checked: true,
      pickerOptions: {
        //禁用当前日期之前的日期
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      memberAudituserqualify: [],
      businessScopeList: [],
      business_id: 0,
    };
  },
  computed: {
    // ...merchantCenterList.mapState(["businessScopeList"]),
  },
  created() {
    this.getList();
    this.getBusiness();
    this.business_id = this.$route.query.id;
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getBusinessScopeList",
      "getBusinessInfo",
    ]),
    ...mapActions(["postscopeConfig"]),
    changeLongime(i) {},
    async getBusiness() {
      let data = await this.getBusinessInfo({
        id: this.$route.query.id,
      });
      data.content.forEach((v) => {
        this.$set(v, "picture", []);
        this.$set(v, "text", []);
        v.detail.forEach((v1) => {
          if (v1.item_type == 3 && v1.img_url) {
            v.picture.push(v1.img_url);
          }
        });
      });
      this.memberAudituserqualify = data.content;
      console.log(this.memberAudituserqualify, "11111111111");
    },
    async getList() {
      let data = await this.getBusinessScopeList({
        business_id: this.$route.query.id,
      });
      data.forEach((v, i) => {
        if (v.expiration_time == "9999-12-31 00:00:00") {
          v.isLongTime = true;
        } else {
          v.isLongTime = false;
        }
        if (v.action == "add") {
          v.business_id = this.$route.query.id;
        }
      });
      this.businessScopeList = data;
    },
    changeBusinessSelect1(i) {
      // console.log(i);
      this.businessScopeList[i].business_id = this.$route.query.id;
    },
    // 保存
    async save() {
      let num = this.businessScopeList.filter((v) => {
        return v.business_id != null;
      });
      if (num.length == 0) {
        this.$message({
          message: "请选择经营范围",
          type: "warning",
        });
        return false;
      }
      let notime = num.filter((v) => {
        return v.expiration_time == null;
      });
      if (notime.length > 0) {
        this.$message({
          message: `请选择 ${notime[0].type}有效期`,
          type: "warning",
        });
        return false;
      }
      let params = JSON.stringify(num);
      params = JSON.parse(params);
      params.forEach((v) => {
        v.business_scope_id = v.id;
        delete v.action;
        delete v.id;
        delete v.isLongTime;
        delete v.type;
      });
      let data = await this.postscopeConfig(params);
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
      }
    },
  },
};
</script>
<style lang='scss' scoped>
.el-checkbox__input.is-checked + .el-checkbox__label {
  color: black;
}
.el-checkbox__label {
  color: black;
}
</style>
<style lang="scss" scoped>
.business {
  width: 100%;
  position: relative;
  // padding-top: 40px;
  .business-1-box {
    width: 100%;
    height: 69vh;
    padding: 0px 20px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: left;
    padding-bottom: 40px;
    .business-1 {
      margin-top: 10px;
      width: 50%;
      min-width: 500px;
      height: 60px;
      line-height: 40px;
      display: flex;
      // justify-content: center;
      // flex: 1;
      // align-items: center;
      .title {
        min-width: 70px;
        font-size: 14px;
        width: 20%;
        color: black !important;
        height: 100%;
      }
      .input-box {
        margin-left: 10px;
        min-width: 150px;
        height: 60px;
      }
    }
    .checks {
      margin-left: 10px;
    }
  }
}
.picture-box {
  display: flex;
  flex-wrap: wrap;
  padding: 0px 80px 40px;
  .picture-item {
    margin-top: 30px;
    margin-right: 50px;
    .img {
      display: flex;
      .upImg {
        width: 140px;
        height: 140px;
        margin-top: 10px;
        .el-image {
          width: 140px;
          height: 140px;
        }
      }
    }
  }
}
.box-message-1 {
  width: 100%;
  height: 100px;
  line-height: 100px;
}
.operate-btn {
  bottom: 30px;
  height: 80px;
  background-color: #fff;
  margin-top: 0;
  position: absolute;
  z-index: 99;
  .save {
    text-align: center;
    line-height: 32px;
    margin-left: 10px;
    width: 88px;
    height: 32px;
    background: #00bfbf;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
  }
  .btn {
    border: 1px solid #999;
    background: #fff;
    color: #999;
  }
  .cancel {
    text-align: center;
    line-height: 40px;
    width: 200px;
    height: 100%;
    border-radius: 4px;
    background: #aaaaaa;
  }
}
.hint {
  border: 1px solid #f00;
  height: 42px;
}
.hintbox {
  height: 20px;
  line-height: 20px;
}
.hinttxet {
  font-size: 12px;
  margin-left: 5px;
  color: #f00;
}
</style>
