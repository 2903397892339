<template>
  <div class="message-box scorr-roll">
    <!-- 文本信息 -->
    <div>
      <div class="posint-box">
        <div>
          <span>选择分账规则</span>
          <el-select
            v-model="name"
            placeholder="请选择分账规则"
            :disabled="$route.query.type == 1 ? true : false"
            @change="changeselect"
          >
            <el-option
              v-for="(item, index) in ListOp"
              :key="index"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div v-if="datatext.day" class="datatext-day">
          当前分账规则中设置的分账时间为【{{
            datatext.day
          }}天】，代表订单状态为“交易结束”开始计时，{{
            datatext.day
          }}天后从平台分账至供应商账户
        </div>
        <!-- <div style="margin: 20px">
          <span>连锁分账方式</span>
          <el-radio-group v-model="chain_ledger_mode">
            <el-radio :label="0">默认方式</el-radio>
            <el-radio :label="1">线上分账</el-radio>
          </el-radio-group>
        </div>
        <div class="datatext-huis">
          默认方式为全额打款给连锁总部，线上分账为线上分账打款
        </div> -->
      </div>
    </div>
    <div class="operate-btn absbtn" v-if="!$route.query.type == 1">
      <div
        class="save1"
        @click="$router.push({ path: '/merchantCenterList' })"
      >
        返回
      </div>
      <div class="save" @click="save">保存</div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  data() {
    return {
      searchData: {},
      ListOp: [],
      datatext: {},
      name: "",
      chain_ledger_mode: "", //连锁分账方式0:默认方式;1:线上分账
    };
  },
  computed: {
    ...merchantCenterList.mapState(["businessInfo"]),
  },
  async created() {
    this.chain_ledger_mode = this.businessInfo.chainLedgerMode;
    await this.getList();
    await this.getMesage();
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getCredentials",
      "getBusinessInfo",
      "getbusiness_ledgerlist",
      "getbusiness_ledgeredit",
      "businessEdit",
    ]),
    cole() {
      this.$router.push({ path: "/merchantCenterList" });
    },
    // 获取赛选
    async getList() {
      let data = await this.getbusiness_ledgerlist();
      this.ListOp = data.data;
      this.datatext = this.ListOp.filter((item) => {
        return item.default == 1;
      });
      if (this.businessInfo.ledgerId != 0) {
        this.name = this.businessInfo.ledgerId;
      } else {
        this.name = this.datatext[0].id;
      }

      this.datatext.length > 0
        ? (this.datatext = this.datatext[0])
        : (this.datatext = {});
    },
    // 获取详情
    async getMesage() {
      let data = await this.getbusiness_ledgeredit({
        id: this.businessInfo.ledgerId,
      });
      this.name = data.data.id;
    },
    changeselect(e) {
      this.datatext = this.ListOp.filter((item) => {
        return item.id == e;
      });
      this.datatext.length > 0
        ? (this.datatext = this.datatext[0])
        : (this.datatext = {});
    },
    //保存
    async save() {
      let data = await this.businessEdit({
        id: this.$route.query.id,
        ledger_id: this.name,
        chain_ledger_mode: this.chain_ledger_mode,
      });
      if (data.code == 200) {
        // this.getBusinessInfo({
        //   id: this.$route.query.id,
        // });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
      await this.getCredentials({
        businessId: this.$route.query.id,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      });
    },
  },
};
</script>

<style lang='scss' scoped>
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
</style>
<style lang="scss" scoped>
.message-box {
  width: 100%;
  // max-height: 75vh;
  // padding-top: 40px;
  padding-left: 20px;
  position: relative;

  .qualification-title {
    font-size: 15px;
    font-weight: 700;
    width: 100%;
    height: 50px;
    color: #00bfbf;
    border-bottom: 1px solid #797979;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
  .qualification-message {
    margin-top: 10px;
    width: 100%;
    .title {
      width: 150px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4 px;
      background: #00bfbf;
      color: #fff;
      font-size: 15px;
      font-weight: 700;
    }
  }
  .qualification-message-img-box {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .makeOut {
    padding-left: 5%;
    padding-right: 5%;
    width: 70%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .makeout-box {
      margin-top: 10px;
      width: 40%;
      height: 40px;
      display: flex;
      .sch-1 {
        width: 80%;
        min-width: 200px;
        display: flex;
        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
    }
  }
}
::v-deep .el-descriptions-item__label {
  width: 45% !important;
  color: #666;
  margin-right: 20px;
  font-size: 14px;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
.posint-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  span {
    font-size: 14px;
    margin-right: 20px;
  }
}
.datatext-day {
  // margin-left: 39%;
  color: #f77234;
  font-size: 14px;
  line-height: 24px;
  width: 331px;
  margin-top: 20px;
}
.datatext-huis {
  color: rgb(245, 154, 35);
  font-size: 14px;
}
</style>
