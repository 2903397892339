<template>
  <div class="pay-box">
    <div class="title">现金支付</div>
    <div class="pay" v-for="(item, index) in cashPaylist" :key="index">
      <div class="pay-change">
        <div class="pay-name">{{ item.payTypeName }}</div>
        <el-switch
          v-model="item.deactivate"
          active-color="#06b7ae"
          inactive-color="#d7d7d7"
          :active-value="0"
          :inactive-value="1"
          :disabled="$route.query.type == 1"
          @change="changsliitem(item, index)"
        >
        </el-switch>
      </div>
      <div class="goods-img" v-if="item.payType == 60">
        <div class="shang-chuan">支付宝收款码上传</div>
        <div class="up-box-bas">
          <!-- <el-upload
              class="avatar-uploader"
              :action="Actions"
              :headers="token"
              :show-file-list="false"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img v-if="pictureUrl" :src="pictureUrl" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              <span v-if="pictureUrl" class="del-btn" @click.stop="hanldeDel">X</span>
            </el-upload> -->
          <el-upload
            :action="Actions"
            list-type="picture-card"
            :limit="1"
            :headers="token"
            :file-list="imageList"
            :before-upload="beforeAvatarUpload"
            :data="{ type: '1' }"
            :on-success="handleAvatarSuccess"
            :on-exceed="onExceed"
          >
            <i class="el-icon-plus"></i>
            <div slot="file" slot-scope="{ file }">
              <img
                class="el-upload-list__item-thumbnail"
                :src="file.url"
                alt=""
              />
              <span class="el-upload-list__item-actions">
                <span
                  class="el-upload-list__item-delete"
                  @click="hanldeDel(file)"
                >
                  <i class="el-icon-delete" title="删除" />
                </span>
              </span>
            </div>
          </el-upload>
        </div>
      </div>
    </div>
    <div class="title" style="margin-top: 30px">金融支付</div>
    <div class="pay" v-for="item in financialList" :key="item.payType">
      <div class="pay-change">
        <div class="pay-name">{{ item.payTypeName }}</div>
        <el-switch
          v-model="item.deactivate"
          active-color="#06b7ae"
          inactive-color="#d7d7d7"
          :active-value="0"
          :inactive-value="1"
          :disabled="$route.query.type == 1"
          @change="changsliitem(item)"
        >
        </el-switch>
      </div>
    </div>
  </div>
</template>

<script>
import { getToken } from "@/utils/auth";
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
const BasicsIndex = createNamespacedHelpers("Basics");
import { wholeUrl } from "@/api/phpUrl"; //引入接口
import compressImage from "@/utils/compressImage.js";
export default {
  data() {
    return {
      imageList: [],
      pictureUrl: "",
      Actions: wholeUrl + "/basis/upload",
      token: { token: getToken() },
      forCorporate: {
        switch: false,
        yygSwitch: false,
      },
      cashPaylist: [],
      financialList: [],
    };
  },

  created() {
    this.GetbusinessGetPayTypeApi();
  },
  methods: {
    ...merchantCenterList.mapActions([
      "GetbusinessGetPayType",
      "postbusinessUpdatePayType",
    ]),
    onExceed(files, fileList) {
      this.$message.error("超出图片数量上限");
    },
    hanldeDel(data) {
      this.imageList = [];
      this.cashPaylist.forEach((item) => {
        if (item.payType == 60) {
          item.pictureUrl = "";
        }
      });
      // let zfbValue = this.cashPaylist.find((item,index) => item.payType == 60)
      // this.changsliitem(zfbValue)
    },
    async beforeAvatarUpload(file) {
      if (file.size / 1024 / 1024 > 50)
        return this.$message.error("上传头像图片大小不能超过 50MB!");
      return await compressImage([file]);
    },
    handleAvatarSuccess(data) {
      if (data.code == 200) {
        this.imageList.push({
          url: data.data.full_url,
        });
        this.cashPaylist.forEach((item) => {
          if (item.payType == 60) {
            item.pictureUrl = data.data.full_url;
          }
        });
        let zfbValue = this.cashPaylist.find(
          (item, index) => item.payType == 60
        );
        this.changsliitem(zfbValue);
        this.$message({
          message: "上传成功",
          type: "success",
        });
        console.log(this.imageList, "11111111111");
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    async GetbusinessGetPayTypeApi() {
      let res = await this.GetbusinessGetPayType({
        supplierId: this.$route.query.id,
      });
      if (res.code == "000000") {
        res.content.forEach((item, index) => {
          if (item.payType == 1 || item.payType == 60 || item.payType == 0) {
            this.cashPaylist.push(item);
            if (item.payType == 60) {
              if (item.pictureUrl) {
                if (this.imageList.length > 0) {
                  this.imageList = [];
                  this.imageList.push({
                    url: item.pictureUrl,
                  });
                } else {
                  this.imageList.push({
                    url: item.pictureUrl,
                  });
                }
              }
            }
          } else if (
            item.payType == 40 ||
            item.payType == 50 ||
            item.payType == 70 ||
            item.payType == 90 ||
            item.payType == 110
          ) {
            this.financialList.push(item);
          }
        });
      }
    },
    async changsliitem(value, index) {
      if (index) {
        if (value.deactivate == 0 && value.payType == 60) {
          if (this.imageList.length <= 0) {
            this.$message({
              message: "请添加支付宝收款码图",
              type: "warning",
            });
            this.cashPaylist[index].deactivate = 1;
            return;
          }
        }
      }
      let params = [...this.cashPaylist, ...this.financialList];
      params.forEach((item, index) => {
        item.supplierId = this.$route.query.id;
      });
      let res = await this.postbusinessUpdatePayType(params);
      if (res.code == "000000") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.cashPaylist = [];
        this.financialList = [];
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
      this.GetbusinessGetPayTypeApi();
    },
  },
};
</script>
<style lang='scss' scoped>
.up-box-bas {
  margin-left: 40px;
}
.up-box-bas .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.up-box-bas .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
</style>
<style lang="scss" scoped>
.title {
  width: 100%;
  font-weight: bold;
  padding-bottom: 16px;
  margin-bottom: 10px;
  border-bottom: dashed 1px #ddd;
  display: flex;
  align-items: center;
}
.title::before {
  width: 4px;
  height: 16px;
  background-color: #06b7ae;
  border-radius: 10px;
  display: inline-block;
  content: "";
  margin-right: 10px;
  margin-bottom: -2px;
}
.pay-box {
  overflow: auto;
  padding-bottom: 40px;
  .pay {
    .pay-change {
      margin-top: 25px;
      display: flex;
    }
    .goods-img {
      // width: 440px;
      text-align: left;
      margin-top: 25px;
      .shang-chuan {
        margin-bottom: 20px;
      }
      .shang-chuan::before {
        width: 4px;
        height: 16px;
        display: inline-block;
        content: "*";
        margin-right: 5px;
        margin-bottom: -2px;
        color: red;
      }
    }
  }
  .pay-name {
    text-align: right;
    width: 140px;
    margin-right: 30px;
  }
}
</style>
