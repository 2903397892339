<template>
  <div class="returnAddress">
    <el-descriptions :column="1">
      <el-descriptions-item label="发货地址">
        <template
          v-if="
            returnArea.delivery_province_name ||
            returnArea.delivery_city_name ||
            returnArea.delivery_district_name
          "
        >
          {{ returnArea.delivery_province_name }}-
          {{ returnArea.delivery_city_name }}
          -{{ returnArea.delivery_district_name }}
        </template>
        <template v-else> /</template>
      </el-descriptions-item>
      <el-descriptions-item label="退货地址">
        <template
          v-if="
            returnArea.return_province_name ||
            returnArea.return_city_name ||
            returnArea.return_district_name
          "
        >
          {{ returnArea.return_province_name }}-
          {{ returnArea.return_city_name }}
          -{{ returnArea.return_district_name }}
        </template>
        <template v-else> /</template>
      </el-descriptions-item>
      <el-descriptions-item label="详细地址">
        {{ returnArea.address || "/" }}
      </el-descriptions-item>
      <el-descriptions-item label="收货人姓名">
        {{ returnArea.name || "/" }}
      </el-descriptions-item>
      <el-descriptions-item label="手机号">
        {{ returnArea.telephone || "/" }}
      </el-descriptions-item>
      <el-descriptions-item label="退货说明">
        <div class="desc_">{{ returnArea.desc_ || "/" }}</div>
        
      </el-descriptions-item>
    </el-descriptions>
    <div class="operate-btn absbtn" v-if="!$route.query.type == 1">
      <div
        class="save1"
        @click="$router.push({ path: '/merchantCenterList' })"
      >
        返回
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  data() {
    return {
      businessInfo: {},
    };
  },
  computed: {
    ...merchantCenterList.mapState(["returnArea"]),
  },
};
</script>

<style lang="scss" scoped>
.returnAddress {
  // padding: 20px;
  position: relative;
}
.desc_{
  width: 206px;
  padding: 5px 5px ;
  background-color: #F2F3F5;
  border-radius: 8px;
}
::v-deep .el-descriptions-item__label{
  width: 42% !important;
  font-size: 14px;
  color: #666;
  margin-right: 20px;
}
::v-deep .el-descriptions-item__content{
color: #333;
}
::v-deep .el-descriptions-item__label.has-colon::after {
  content: "";
}
::v-deep .el-descriptions-item__container{
  align-items: flex-start;
}
</style>
