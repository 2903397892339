<template>
  <div class="MERCHds" style="height: 80vh">
    <div class="merchardds disflex">
      <!-- tag标签 -->
      <div class="merch-tag">
        <!-- <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="企业信息" name="0"> </el-tab-pane>
          <el-tab-pane label="经营范围" name="1"></el-tab-pane>
          <el-tab-pane label="可售区域" name="2"> </el-tab-pane>
          <el-tab-pane label="退货地址" name="3"> </el-tab-pane>
          <el-tab-pane label="白条规则" name="4"> </el-tab-pane>
          <el-tab-pane label="分账规则" name="5"> </el-tab-pane>
        </el-tabs> -->
        <div
          style="cursor: pointer"
          v-for="(item, index) in tabList"
          :key="index"
          @click="handleClick(index)"
          :class="activeName == index ? 'active-name' : ''"
        >
          {{ item }}
        </div>
      </div>
      <div class="merch-box">
        <div v-if="activeName == 0">
          <Message />
        </div>
        <!-- <div v-if="activeName == 1">
          <Business />
        </div> -->
        <div v-if="activeName == 1">
          <Area />
        </div>
        <div v-if="activeName == 2">
          <returnAddress />
        </div>
        <div v-if="activeName == 3">
          <baitiao />
        </div>
        <div v-if="activeName == 4">
          <posint />
        </div>
        <div v-if="activeName == 5">
          <payWay />
        </div>
        <div v-if="activeName == 6">
          <autoClear />
        </div>
        <span
          class="operate-btn absbtn"
          v-if="$route.query.type == 1 && activeName != 0"
        >
          <span class="save1" @click="toleave">关闭</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import Message from "./merchCOM/message.vue";
import Business from "./merchCOM/business.vue";
import Area from "./merchCOM/area.vue";
import returnAddress from "./merchCOM/returnAddress.vue";
import baitiao from "./merchCOM/baitiao.vue";
import posint from "./merchCOM/posint.vue";
import payWay from "./merchCOM/payWay.vue";
import autoClear from "./merchCOM/autoClear.vue";
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  data() {
    return {
      tabList: [
        "企业信息",
        // "经营范围",
        "可售区域",
        "退货地址",
        "白条规则",
        "分账规则",
        "支付方式",
        "自动请货",
      ],
      activeName: 0,
      content: {
        content: "",
      },
      business_id: "",
      items: {},
    };
  },
  components: {
    Message,
    Business,
    Area,
    returnAddress,
    baitiao,
    posint,
    payWay,
    autoClear,
  },
  created() {
    let id = this.$route.query.id;
    this.business_id = id;
    // 获取供应商信息
    this.getBusiness();
    // 获取供应商经营范围
    // this.getBusinessScopeList({ business_id: id });
    // 获取可经营地区列表
    this.getDistrictList({ business_id: id });
    // 获取退货地址
    this.getbusinessgetReturnAddress({ id: id });
  },
  methods: {
    ...merchantCenterList.mapActions([
      "getCredentials",
      "getBusinessScopeList",
      "getDistrictList",
      "getbusinessgetReturnAddress",
    ]),
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab) {
      this.activeName = tab;
      //  //console.log(tab, event);
    },
    async getBusiness() {
      let data = await this.getCredentials({
        businessId: this.$route.query.id,
        auditSerialNumber: this.$route.query.auditSerialNumber,
        isBusinessInfo: this.$route.query.isBusinessInfo,
      });
      this.items = data;
    },

    // 保存
    conserve() {},
    // 关闭
    toleave() {
      this.$router.push({ path: "/merchantCenterList" });
    },
    // 富文本类容
    onEditorChange() {},
  },
};
</script>
<style lang="scss" scoped>
.editor {
  line-height: normal !important;
  //   height: 500px;
}
.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}
.ql-snow .ql-tooltip.ql-editing {
  left: 100px !important;
}
.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}
.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}
.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "标准字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="serif"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="serif"]::before {
  content: "衬线字体";
}
.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="monospace"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="monospace"]::before {
  content: "等宽字体";
}
</style>
<style lang="scss" scoped>
.MERCHds {
  width: 100%;
  .merchardds {
    height: 84vh;
    width: 100%;
    padding: 20px;
    padding-top: 30px;
    border-radius: 4px;
    background: #ffffff;
    padding-bottom: 50px;
    .merch-tag {
      width: 100%;
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      margin-top: 10px;
      div {
        text-align: center;
        flex: 1;
        color: #333333;
        border-bottom: 1px solid #f1f1f1;
      }
      .active-name {
        border-bottom: 1px solid #06b7ae;
      }
    }
    .richText {
      margin-top: 30px;
      max-width: 800px;
      max-height: 85%;
      min-height: 50vh;
    }
    .compile-BTN {
      margin-top: 30px;
      width: 100%;
      display: flex;
      .cancel-btn {
        width: 100px;
        height: 40px;
        line-height: 40px;
        background: #f5f6f7;
        color: #c6c7c9;
        text-align: center;
        border-radius: 5px;
        cursor: pointer;
      }
      .save-btn {
        margin-left: 20px;
        background: #3d70ff;
        color: #fff;
      }
    }
  }
}
.disflex {
  height: 100%;
  // display: flex;
  // flex-wrap: wrap;
  .merch-box {
    // flex: 1;
    height: 100%;
    width: 100%;
    position: relative;
    padding-top: 40px;
    div {
      height: 100%;
      width: 100%;
    }
  }
}
</style>
