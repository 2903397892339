<template>
  <div class="autoClear">
    <div v-if="mess.status == -1" >
      <span class="lable">自动请货对接状态 </span><span>{{ mess.statusName }}</span>
    </div>
    <div v-else>
      <div>
        <span class="lable">支持支付类型</span><span>{{ mess.payTypeName }}</span>
      </div>
      <div v-if="mess.payType==1">
        <span class="lable">支持自动付款方式 </span><span>{{ mess.autoPayWayName }}</span>
      </div>
      <div>
        <span class="lable">状态 </span><span>{{ mess.statusName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const merchantCenterList = createNamespacedHelpers("merchantCenterList");
export default {
  data() {
    return {
      mess: {},
    };
  },
  async created() {
    let data = await this.requisitionConfigDetail({
      businessId: this.$route.query.id,
    });
    if (data.code == "200") {
      this.mess = data.data;
    }
  },
  methods: {
    ...merchantCenterList.mapActions(["requisitionConfigDetail"]),
  },
};
</script>

<style lang="scss" scoped>
span{
    font-size: 16px;
    color: #666;
}
.lable{
    width: 180px;
    display: inline-block;
    text-align: end;
    font-weight: bold;
    color: #333;
    margin-right: 20px;
    margin-bottom: 20px;
}
</style>
